const routes = [
    {
        path: "/ects/coordinator/courses",
        component: () => import("../pages/coordinator-courses/Index"),
        meta: { isAuthenticated: true,
         permission:'ects_coordinatorcourses'
        }
    }
];

export default routes;

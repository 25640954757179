const routes = [
    {
        path: "/yoksis/parameters",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "yoksisservice_yoksisparameter"
        }
    },
    {
        path: "/yoksis/parameter/items/:id",
        component: () => import("../pages/Parameteritem"),
        meta: {
            isAuthenticated: true,
            permission: "yoksisservice_yoksisparameteritem"
        },
    },
];

export default routes;

import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import Base from "./Base";

// Store
import store from "@/plugins/Store";

// Routers
import advising from "@/modules/advising/router/index";
import advisorBulk from "@/modules/advisorBulk/router/index";
import asal from "@/modules/asalRegration/router/index";
import attendanceEntry from "@/modules/attendanceEntry/router/index";
import announcement from "@/modules/announcement/router/index";
import auth from "@/modules/auth/router/index";
import boardDecisions from "@/modules/boardDecisions/router/index";
import boardMembers from "@/modules/boardMembers/router/index";
import building from "@/modules/building/router/index";
import campuses from "@/modules/campus/router/index";
import city from "@/modules/city/router/index";
import classroom from "@/modules/classroom/router/index";
import courseOutcome from "@/modules/courseOutcome/router/index";
import certificate from "@/modules/certificate/router/index";
import certificateApplication from "@/modules/certificateApplication/router/index";
import certificateCenter from "@/modules/certificateCenter/router/index";
import certificateCenterCertificate from "@/modules/certificateCenterCertificate/router/index";
import certificatePerson from "@/modules/certificatePerson/router/index";
import certificateShow from "@/modules/certificateShow/router/index";
import coopcertificate from "@/modules/coopsCertificate/router/index";
import coopstudencerttificate from "@/modules/coopStudentCertificate/router/index";
import contactCreate from "@/modules/contactModule/router/index.js";
import constants from "@/modules/constants/router/index";
import courseActivities from "@/modules/courseActivities/router/index";
import country from "@/modules/country/router/index";
import courseRegistration from "@/modules/courseRegistration/router/index";
import courseRegistrationReservation from "@/modules/courseRegistrationReservation/router/index";
import courseSchedule from "@/modules/courseSchedule/router/index";
import courseTransfers from "@/modules/courseTransfers/router/index";
import courseprerequisites from "@/modules/coursePrerequisites/router/index";
import courses from "@/modules/courses/router/index";
import courseMergeSections from "@/modules/courseMergeSections/router/index";
import curriculum from "@/modules/curriculum/router/index";
import customers from "@/modules/customers/router/index";
import dashboard from "@/modules/dashboard/router/index";
import departments from "@/modules/departments/router/index";
import disciplinaryProcess from "@/modules/disciplinaryProcess/router/index";
import district from "@/modules/district/router/index";
import documentRequest from "@/modules/documentRequest/router/index";
import electivePools from "@/modules/electivePools/router/index";
import epayment from "@/modules/epayment/router/index";
import examSchedule from "@/modules/examSchedule/router/index";
import examDataCollection from "@/modules/examDataCollection/router/index";
import faculties from "@/modules/faculties/router/index";
import gradeConversions from "@/modules/gradeConversions/router/index";
import gradeEntry from "@/modules/gradeEntry/router/index";
import gradeSystems from "@/modules/gradingSystems/router/index";
import graduationInBulk from "@/modules/graduationInBulk/router/index";
import instructorExamSchedule from "@/modules/instructorExamSchedule/router/index";
import job from "@/modules/jobs/router/index";
import leaveOfAbsences from "@/modules/leaveOfAbsences/router/index";
import leaveWithConsent from "@/modules/leaveWithConsent/router/index";
import makeUpExam from "@/modules/makeUpExam/router/index";
import medicinegroup from "@/modules/medicineGroup/router/index";
import menu from "@/modules/menu/router/index";
import messageBox from "@/modules/message/router/index";
import minorApplication from "@/modules/minorApplication/router/index";
import doubleMajor from "@/modules/doubleMajorr/router/index";
import minorApplicationPreference from "@/modules/minorApplicationPreference/router/index";
import minorApplicationQuota from "@/modules/minorApplicationQuota/router/index";
import onlineRegistration from "@/modules/onlineRegistration/router/index";
import osymcode from "@/modules/osymCode/router/index";
import osymquota from "@/modules/osymQuota/router/index";
import otherPayments from "@/modules/otherPayments/router/index";
import parameter from "@/modules/parameter/router/index";
import paymentApprovalException from "@/modules/paymentApprovalExceptions/router/index";
import paymentPlans from "@/modules/paymentPlans/router/index";
import payments from "@/modules/payments/router/index";
import paymenttypes from "@/modules/paymenttypes/router/index";
import period from "@/modules/periods/router/index";
import placementExamClassrooms from "@/modules/placementExamClassrooms/router/index";
import placementExamPartClassrooms from "@/modules/placementExamPartClassrooms/router/index";
import placementExamScore from "@/modules/placementExamScore/router/index";
import placementExams from "@/modules/placementExams/router/index";
import prepAttendance from "@/modules/prepAttendance/router/index";
import prepClasses from "@/modules/prepClasses/router/index";
import prepExamDefinitions from "@/modules/prepExamDefinitions/router/index";
import prepExamScores from "@/modules/prepExamScores/router/index";
import prepModules from "@/modules/prepModules/router/index";
import prepReports from "@/modules/prepReports/router/index";
import prepSchedule from "@/modules/prepSchedule/router/index";
import programBasicField from "@/modules/programBasicField/router/index"
import prepScheduleDraft from "@/modules/prepScheduleDraft/router/index";
import courseAssessment from "@/modules/courseAssessment/router/index";
import prepStudents from "@/modules/prepStudents/router/index";
import prices from "@/modules/prices/router/index";
import simulationPrices from "@/modules/simulationPrices/router/index";
import product from "@/modules/product/router/index";
import productDiscount from "@/modules/productDiscount/router/index";
import programs from "@/modules/programs/router/index";
import programOutcome from "@/modules/programOutcome/router/index"
import programOutcomeDefinition from "@/modules/programOutcomeDefinition/router/index"
import programGroups from "@/modules/programGroups/router/index";
import quotaManagement from "@/modules/quotaManagement/router/index";
import courseProgramOutcome from "@/modules/courseProgramOutcome/router/index";
import receipt from "@/modules/receipt/router/index";
import refund from "@/modules/refund/router/index";
import registration from "@/modules/registrations/router/index";
import registrationDate from "@/modules/registrationDate/router/index";
import reports from "@/modules/reports/router/index";
import sendEsm from "@/modules/sendSms/router/index";
import reservation from "@/modules/reservation/router/index";
import role from "@/modules/role/router/index";
import roleFilter from "@/modules/roleFilter/router/index";
import scholarshiptypes from "@/modules/scholarshiptypes/router/index";
import school from "@/modules/school/router/index";
import sections from "@/modules/sections/router/index";
import semester from "@/modules/semester/router/index";
import semesterdate from "@/modules/semesterDate/router/index";
import settings from "@/modules/settings/router/index";
import slot from "@/modules/slots/router/index";
import staff from "@/modules/staff/router/index";
import studentCourseSimulation from "@/modules/studentCourseSimulation/router/index";
import studentCourses from "@/modules/studentCourses/router/index";
import studentDocument from "@/modules/studentDocument/router/index";
import studentProgram from "@/modules/studentProgram/router/index";
import studentSemester from "@/modules/studentSemester/router/index";
import students from "@/modules/students/router/index";
import studentCard from "@/modules/studentCard/router";
import system from "@/modules/system/router";
import lms from  "@/modules/lms/router/index";
import thesisAndProject from "@/modules/thesisAndProject/router/index";
import undergraduateTransfer from "@/modules/undergraduateTransfer/router/index";
import undergraduateTransferQuota from "@/modules/undergraduateTransferQuota/router/index";
import unit from "@/modules/unit/router/index";
import universities from "@/modules/universities/router/index";
import universityCourses from "@/modules/universityCourses/router/index";
import universityGrades from "@/modules/universityGrades/router/index";
import user from "@/modules/users/router/index";
import virtualPos from "@/modules/virtualPos/router/index";
import year from "@/modules/year/router/index";
import yoksisUnite from "@/modules/yoksisUnites/router/index";
import withDraw from "@/modules/withDraw/router/index";
import proformaInvoice from "@/modules/proformaInvoice/router/index";
import financeReport from "@/modules/financeReports/router/index";
import surveys from "@/modules/surveys/router/index";
import invoices from "@/modules/invoices/router/index";
import graduateApplication from "@/modules/graduateApplication/router/index";
import lecturerReports from "@/modules/lecturerReports/router/index";
import highhonor from "@/modules/highHonor/router/index";
import holiday from "@/modules/holiday/router/index"
import userModelFilter from "@/modules/userModelFilter/router/index";
import person from "@/modules/person/router/index";
import yoksisOperations from "@/modules/yoksisOperations/router/index";
import TeacherClassroomFilter from "@/modules/TeacherClassroomFilter/router/index";
import coopPublicScholarships from "@/modules/coopPublicScholarships/router/index";
import doubleCourseControl from "@/modules/doubleCourseControl/router/index";
import qrVerification from "@/modules/qrVerification/router/index";
import oauth from "@/modules/oauth/router/index";
import unihall from "@/modules/unihall/router/index";
import payzee from "@/modules/payzee/router/index";
import preventCourseRegistration from "@/modules/preventCourseRegistration/router/index";
import courseSchedulesAttendance from "@/modules/courseSchedulesAttendance/router/Index";
import doubleMajorApplicationQuota from "@/modules/doubleMajorApplicaitonQuota/router/index";
import unidentifiedPayment from "@/modules/unidentifiedPayments/router/index";
import receipts from "@/modules/receipts/router/index";
import logs from "@/modules/logs/router/index";
import turkmerApplicationForm from "@/modules/turkmerApplicationForm/router/Index";
import translations from "@/modules/translations/router/index";
import excelImportTemplate from "@/modules/excelImportTemplate/router/index";
import uploadedStudentDocuments from "@/modules/uploadedStudentDocuments/router/index";
import graduates from "@/modules/graduates/router/index";
import myProfile from "@/modules/myProfile/router/index";
import bulkImport from "@/modules/bulkImport/router/index";
import approvalTemplate from "@/modules/approvalTemplate/router/index";
import systemLogo from "@/modules/systemLogo/router";
import vinovInstructions from "@/modules/vinovInstructions/router";
import paymentPackages from "@/modules/paymentPackages/router";
import conservatoryApplication from "@/modules/conservatoryApplication/router";
import warnings from "@/modules/warnings/router";
import fashionDesignApplication from "@/modules/fashionDesignApplication/router";
import proficiencyExamScore from "@/modules/proficiencyExamScore/router"
import studentClubs from "@/modules/studentClubs/router/index";
import studentClubMemberSearch from "@/modules/studentClubMemberSearch/router/index";
import studentClubSelectionSearch from "@/modules/studentClubSelectionSearch/router/index";
import tyycLevelOutcomes from "@/modules/tyycLevelOutcomes/router"
import tyycBasicFieldOutcomes from "@/modules/tyycBasicFieldOutcomes/router"
import turkmerApplication from "@/modules/turkmerApplication/router/index";
import yoksisParameter from "@/modules/yoksisParameter/router/index";
import ects from "@/modules/ects/router/index";

Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes: [
        {
            path: "/",
            component: () => import("@/pages/Index"),
            meta: { isAuthenticated: true },
        },
        ...advising,
        ...advisorBulk,
        ...asal,
        ...attendanceEntry,
        ...auth,
        ...announcement,
        ...boardDecisions,
        ...boardMembers,
        ...building,
        ...campuses,
        ...certificate,
        ...certificateApplication,
        ...certificateCenter,
        ...certificateCenterCertificate,
        ...certificatePerson,
        ...certificateShow,
        ...city,
        ...classroom,
        ...constants,
        ...contactCreate,
        ...country,
        ...courseAssessment,
        ...coopcertificate,
        ...coopstudencerttificate,
        ...courseRegistration,
        ...courseRegistrationReservation,
        ...courseSchedule,
        ...courseTransfers,
        ...courseprerequisites,
        ...courses,
        ...courseOutcome,
        ...courseMergeSections,
        ...curriculum,
        ...customers,
        ...courseProgramOutcome,
        ...courseActivities,
        ...dashboard,
        ...departments,
        ...disciplinaryProcess,
        ...district,
        ...documentRequest,
        ...electivePools,
        ...examDataCollection,
        ...epayment,
        ...examSchedule,
        ...faculties,
        ...gradeConversions,
        ...gradeEntry,
        ...gradeSystems,
        ...graduationInBulk,
        ...instructorExamSchedule,
        ...job,
        ...leaveOfAbsences,
        ...lms,
        ...leaveWithConsent,
        ...makeUpExam,
        ...medicinegroup,
        ...menu,
        ...messageBox,
        ...minorApplication,
        ...minorApplicationPreference,
        ...minorApplicationQuota,
        ...onlineRegistration,
        ...osymcode,
        ...osymquota,
        ...otherPayments,
        ...parameter,
        ...programBasicField,
        ...paymentApprovalException,
        ...paymentPlans,
        ...payments,
        ...paymenttypes,
        ...period,
        ...placementExamClassrooms,
        ...placementExamPartClassrooms,
        ...placementExamScore,
        ...placementExams,
        ...prepAttendance,
        ...prepClasses,
        ...prepExamDefinitions,
        ...prepExamScores,
        ...prepModules,
        ...prepReports,
        ...prepSchedule,
        ...prepScheduleDraft,
        ...prepStudents,
        ...prices,
        ...programOutcome,
        ...programOutcomeDefinition,
        ...simulationPrices,
        ...product,
        ...productDiscount,
        ...programs,
        ...programGroups,
        ...quotaManagement,
        ...receipt,
        ...refund,
        ...registration,
        ...registrationDate,
        ...reports,
        ...reservation,
        ...role,
        ...roleFilter,
        ...scholarshiptypes,
        ...school,
        ...sections,
        ...semester,
        ...semesterdate,
        ...settings,
        ...slot,
        ...staff,
        ...studentCourseSimulation,
        ...studentCard,
        ...system,
        ...studentCourses,
        ...studentDocument,
        ...studentProgram,
        ...studentSemester,
        ...students,
        ...thesisAndProject,
        ...undergraduateTransfer,
        ...undergraduateTransferQuota,
        ...unit,
        ...universities,
        ...universityCourses,
        ...universityGrades,
        ...user,
        ...virtualPos,
        ...year,
        ...yoksisUnite,
        ...withDraw,
        ...holiday,
        ...proformaInvoice,
        ...financeReport,
        ...surveys,
        ...sendEsm,
        ...invoices,
        ...graduateApplication,
        ...lecturerReports,
        ...highhonor,
        ...userModelFilter,
        ...person,
        ...yoksisOperations,
        ...TeacherClassroomFilter,
        ...coopPublicScholarships,
        ...doubleCourseControl,
        ...qrVerification,
        ...oauth,
        ...unihall,
        ...payzee,
        ...preventCourseRegistration,
        ...courseSchedulesAttendance,
        ...doubleMajor,
        ...doubleMajorApplicationQuota,
        ...unidentifiedPayment,
        ...receipts,
        ...logs,
        ...turkmerApplicationForm,
        ...translations,
        ...uploadedStudentDocuments,
        ...graduates,
        ...myProfile,
        ...bulkImport,
        ...approvalTemplate,
        ...systemLogo,
        ...vinovInstructions,
        ...paymentPackages,
        ...conservatoryApplication,
        ...warnings,
        ...fashionDesignApplication,
        ...proficiencyExamScore,
        ...excelImportTemplate,
        ...studentClubs,
        ...studentClubMemberSearch,
        ...studentClubSelectionSearch,
        ...tyycLevelOutcomes,
        ...tyycBasicFieldOutcomes,
        ...turkmerApplication,
        ...yoksisParameter,
        ...ects,

        {
            path: "/special-student",
            name: "special-student",
            component: () => import("@/pages/error/SpeacialStudent"),
        },
        {
            path: "/404",
            name: "404",
            component: () => import("@/pages/error/404"),
        },
        {
            path: "/403",
            name: "403",
            component: () => import("@/pages/error/403"),
        },
        {
            path: "*",
            name: "Not Found",
            component: () => import("@/pages/error/404"),
        },
        {
            path: "/503",
            name: "503",
            component: () => import("@/pages/error/503"),
        },
    ],
});
router.beforeEach((to, from, next) => {
    if(from.path=='/login' && to.path=='/logout'){
        return;
    }
    NProgress.start();
    // For E-Payment Layout
    if (to.matched.some((record) => record.meta.isEpaymentAuth)) {
        if (
            !Base.LocalStorage.get("epayment_token") ||
            !Base.LocalStorage.get("epayment_user")
        ) {
            next({
                path: "/epayment/login",
            });
        } else {
            next();
        }
    }

    // Online Registration Layout
    if (to.matched.some((record) => record.meta.isOnlineRegistration)) {
        if (!Base.LocalStorage.get("or_access_token")) {
            next({
                path: "/online/registration/login",
            });
        } else {
            next();
        }
    }

    // UnderGraduate Transfer
    if (to.matched.some((record) => record.meta.isUndergraduateTransferLogin)) {
        if (
            !Base.LocalStorage.get("undergraduate_transfer_data") ||
            !Base.LocalStorage.get("undergraduate_transfer_username") ||
            !Base.LocalStorage.get("undergraduate_transfer_pin")
        ) {
            next({
                path: "/transfer/login",
            });
        } else {
            next();
        }
    }

    // Turkmer
    if (to.matched.some((record) => record.meta.isTurkmerLogin)) {
        if (
            !Base.LocalStorage.get("turkmer_application_data") ||
            !Base.LocalStorage.get("turkmer_application_username") ||
            !Base.LocalStorage.get("turkmer_application_pin")
        ) {
            next({
                path: "/turkmer/login",
            });
        } else {
            next();
        }
    }

    // App Layout
    if (to.matched.some((record) => record.meta.isAuthenticated)) {
        if (!Base.LocalStorage.get("access_token")) {
            store.dispatch("auth/clearUserInfoAndToken");

            next({
                path: "/login",
                query: { redirect: to.fullPath },
            });
        }
    }

    let user = Base.LocalStorage.get("user");
    // Surveys
    if (
        user &&
        user.surveys &&
        user.surveys.length > 0 &&
        typeof to.meta.isAuthenticated != "undefined"
    ) {
        if (
            typeof from.meta.isSurvey != "undefined" &&
            typeof to.meta.isSurvey == "undefined"
        ) {
            NProgress.done();
            return;
        }
        if (typeof to.meta.isSurvey == "undefined") {
            for (const item of user.surveys) {
                if (item.necessity === 1) {
                    next({
                        path:
                            "/surveys/" +
                            item.id +
                            "/evaluate/" +
                            (item.related_id ?? 0),
                    });
                }
            }
        }
    }
    // Collect Contact Info
    else if (
        user &&
        (user.collect_mobile_tel || user.collect_personal_email) &&
        typeof to.meta.isAuthenticated != "undefined"
    ) {
        if (typeof from.meta.isUpdateContactInfo != "undefined") {
            NProgress.done();
            return;
        }
        if (typeof to.meta.isUpdateContactInfo == "undefined") {
            next({ path: "/update-contact-info" });
        }
    }
    //Course confirmation
    else if (
        user &&
        user.get_course_confirmation &&
        typeof to.meta.isAuthenticated != "undefined"
    ) {
        if (typeof from.meta.isMyCourses != "undefined") {
            NProgress.done();
            return;
        }
        if (typeof to.meta.isMyCourses == "undefined") {
            next({ path: "/my-courses" });
        }
    }
    else if(user && user.warning){
        if(to.path != '/warning'){
            next({ path: "/warning" });
            NProgress.done();
        }
    }

    // Admin
    if (to.matched.some((record) => record.meta.isAdmin)) {
        if (
            typeof user == "undefined" ||
            typeof user.active_role == "undefined" ||
            typeof user.active_role.name == "undefined" ||
            user.active_role.name !== "A"
        ) {
            next({ path: "/403" });
        }
    }

    // Staff
    if (to.matched.some((record) => record.meta.isStaff)) {
        if (
            typeof user == "undefined" ||
            typeof user.active_role == "undefined" ||
            typeof user.active_role.name == "undefined" ||
            user.active_role.name === "OGR"
        ) {
            next({ path: "/403" });
        }
    }

    // Student
    if (to.matched.some((record) => record.meta.isStudent)) {
        if (
            typeof user == "undefined" ||
            typeof user.active_role == "undefined" ||
            typeof user.active_role.name == "undefined" ||
            user.active_role.name !== "OGR"
        ) {
            next({ path: "/403" });
        }
    }

    // Permission
    if (typeof to.meta.permission != "undefined" && to.meta.permission) {
        if (user.permissions.length == 0) {
            next({ path: "/403" });
        }
        let isThere = user.permissions.includes(to.meta.permission);
        if (!isThere) {
            next({ path: "/403" });
        }
    }
    let checkSpecialStudent = () => {
        if (
            user &&
            user.student &&
            (user.student.student_program || []).length
        ) {
            for (const [
                key,
                program,
            ] of user.student.student_program.entries()) {
                if (program.registration_type == 228) return true;
            }
        }
    };
    // SpeacialStudent
    if (
        !to.matched.some((record) => {
            if (
                record.meta.isSpecialStudent != null &&
                to.meta.isAuthenticated != null
            ) {
                if (checkSpecialStudent()) {
                    return record.meta.isSpecialStudent;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })
    ) {
        next({ path: "/special-student" });
    }
    next();
});
router.afterEach(() => {
    NProgress.done();
});
router.onError((error) => {
    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
        window.location.reload();
    }
});

export default router;
